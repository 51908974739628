<template>
	<div class="log">
		<div class="login">
			<div class="login-width">
				<div class="login-center-width">
					<div class="login-center-width-img">
            <Isvg icon-class="logo-text" class-name="item-logo" />
          </div>
					<div v-show="showLogin">
						<div class="login-center-width-dxzh">
							<div class="login-duanxi">
								<button ref="btn1" class="button-200" @click="showback" :class="{ active: checkout }">短信验证码登录</button>
							</div>
							<div class="login-zhanghao">
								<button ref="btn2" class="button-200" @click="showBck1" :class="{ active: !checkout }">密码登录</button>
							</div>
						</div>
						<ValidationObserver ref="OneForm">
							<div class="login-duanxi-input" v-if="type == login_types.fast">
								<div class="login-duanxi-input1">
									<ValidationProvider rules="required|mobile" name="手机账号" v-slot="{ errors }" mode="passive">
										<input
											class="input-400 login-input-heightbot login-input-block"
											type="number"
                      ref="input1"
											placeholder="请输入手机号码"
											v-model.trim.number="account"
											:autofocus="true"
											min="1"
										/>
										<p>{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
								<div class="login-duanxi-input2">
									<ValidationProvider rules="required" name="验证码" v-slot="{ errors }" class="input-250" mode="passive">
										<div class="login-duanxi-yanzheng">
											<input
												v-model.trim="code"
												name="password"
												type="text"
												placeholder="请输入验证码"
												class="input-250 login-input-heightbot login-input-block"
											/>

											<button :class="'button-blue-150' + btnStyle" @click="getSendCode(this)" ref="buttonNo">{{ codeContent }}</button>
										</div>
										<p>{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
								<div class="login-button">
									<el-button type="button" class="button-blue-400" @click="submitForm(login_types.fast)" :loading="userLoding">登录</el-button>
								</div>
							</div>
						</ValidationObserver>
						<ValidationObserver ref="TwoForm">
							<div class="login-zhanghao-input" v-if="type == login_types.account">
								<div class="login-zhanghao-input1">
									<ValidationProvider rules="required|mobile" name="用户名" v-slot="{ errors }" mode="passive">
										<input
											type="text"
											v-model.trim="account"
											placeholder="请输入手机号码"
                      ref="input2"
                      :autofocus="true"
											class="input-400 login-input-heightbot login-input-block"
										/>
										<p>{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
								<div class="login-zhanghao-input2">
                  <Isvg icon-class="eye-close" class-name="visible-password" @click.native="showPas" v-if="pas == 1"/>
                  <Isvg icon-class="eye-open" class-name="invisible-password" @click.native="blankPas" v-if="pas == 2"/>
									<ValidationProvider rules="required" name="密码" v-slot="{ errors }" mode="passive">
										<input
											ref="showPas"
											type="password"
											name="password"
											v-model.trim="password"
											placeholder="请输入密码"
											class="input-400 login-input-heightbot login-input-block"
										/>
										<p>{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
								<div class="login-zhanghao-button">
									<el-button @click="submitForm(login_types.account)" class="button-blue-400" :loading="accountLoding">登录</el-button>
								</div>
							</div>
						</ValidationObserver>
						<div class="login-jizhu">
							<div class="login-left">
								<input v-model="remeber_login" type="checkbox" name="remerber" />
								<span class="login-left-span">记住账号密码，10天内免登录</span>
								<span class="span" @click="$router.push('/retrievePassword')">忘记密码？</span>
							</div>
						</div>
						<div class="hui"></div>
						<div class="register-zhanghao">
							<svg class="icon" aria-hidden="true" @click="showWechate"><use xlink:href="#icon-WeChat"></use></svg>
						</div>
						<p class="register-goLogin">
							还没有账号？
							<a href="javascript:void(0);" @click="$router.push('/register')">立即注册</a>
						</p>
					</div>
				</div>
				<CheckWechat v-if="LWhate"/>
			</div>
			<p class="register-beihao">© 2022 gogowork.cn All Rights Reserved 京IPC备：2021019219号-1</p>
		</div>
	</div>
</template>

<script>
import { requestLogin, requestSendCode } from '@/api/index';
import CheckWechat from '@/components/CheckWechat';
import redirectUrl from "@/utils/redirectUrl";

export default {
	name: 'login',
	components: {
		CheckWechat
	},
	data() {
		return {
			login_types: {
				account: 1, //'账号密码登录',
				anonymous: 2, //'匿名登录',
				fast: 3 //'快捷登录'
			},
			type: 3,
			// 用户名和邮箱
			account: '',
			// 密码
			password: '',
			// 返回回来的数据
			results: {},
			// 用户名和邮箱的报错提醒
			erraccount: [],
			// 手机号
			mobile: '',
			//登录类型
			code_type: '2',
			//记住账号
			remeber_login: 0,
			//验证码
			code: '',
			// 密码的显示与隐藏
			pas: 1,

			wait: 60,
			codeContent: '发送验证码',
			canClick: true,
			btnStyle: '',
			LWhate: false,
			showLogin: true,
			// 短信验证码loding效果
			userLoding: false,
			// 账号密码loding效果
			accountLoding: false,
			canLogin: true
		};
	},
  computed: {
    checkout() {
      return this.type == 3;
    }
  },
  mounted() {
    this.$bus.$on('toLogins', () => {
      this.LWhate = false;
      this.showLogin = true;
    });
    this.keyDown();
  },
	created() {},
	methods: {
		/* 用户名和邮箱登录 */
		async submitForm(LoginType) {
			if (!this.canLogin) {
				return;
			}
			this.canLogin = false;
			var that = this;
			setTimeout(function() {
				that.canLogin = true;
			}, 2000);
			if (LoginType == this.login_types.fast) {
				this.$refs.OneForm.validate().then(success => {
					if (!success) {
						return;
					} else {
						this.login(LoginType);
					}
				});
			} else {
				this.$refs.TwoForm.validate().then(success => {
					if (!success) {
						return;
					} else {
						this.login(LoginType);
					}
				});
			}
		},
		async login(LoginType) {
			let data = {
				type: LoginType,
				account: this.account,
				password: this.password,
				code_type: this.code_type,
				remeber_login: this.remeber_login ? 1 : 0,
				code: this.code
			};
			const res = await requestLogin(data);
			if (res.statusCode == 200) {
				this.userLoding = true;
				this.accountLoding = true;
        this.$store.dispatch('useUserStore/setLoginStatusInfo', res.data)
        this.$redirectUrl.jumpToFrom('/home/page', true);
			} else {
				this.$notify({
					message: res.message,
					type: 'warning'
				});
			}
		},
		getSendCode(obj) {
			if (this.canClick) {
				// 单独验证手机号
				this.$validate(this.account, 'required|mobile', {
					name: '手机号'
				}).then(result => {
					if (result.valid) {
						this.canClick = false;
						// 开始倒计时
						this.$refs.buttonNo.style = 'cursor: not-allowed';
						this.btnStyle = ' disabled';
						this.codeContent = this.wait + '秒';
						let clock = window.setInterval(() => {
							this.wait--;
							this.codeContent = this.wait + '秒';
							if (this.wait < 0) {
								//当倒计时小于0时清除定时器
								window.clearInterval(clock);
								this.codeContent = '重新发送验证码';
								this.wait = 60;
								this.canClick = true;
								this.btnStyle = '';
								this.$refs.buttonNo.style = 'cursor: pointer';
							}
						}, 2000);
						this.toSendCode();
					} else {
						this.$notify({
							message: '手机号格式错误',
							type: 'error'
						});
					}
				});
			}
		},
		async toSendCode() {
			let { account, code_type } = this;
			let res = await requestSendCode({ account, code_type });
			if (res.statusCode == 200) {
				this.$notify({
					message: res.data,
					type: 'success'
				});
			} else {
				this.$notify({
					message: res.message,
					type: 'warn'
				});
			}
		},
		resetForm(LoginType) {
			// 重置字段值
			this.account = this.mobile = this.code = this.password = '';
			// 清空表单
			this.$nextTick(() => {
				if (LoginType == this.login_types.fast) {
					// this.$refs.OneForm.resetFields();
				} else {
					// this.$refs.TwoForm.resetFields();
				}
			});
		},
		// 显示密码
		showPas() {
			this.pas = 2;
			this.$refs.showPas.type = 'text';
		},
		// 隐藏密码
		blankPas() {
			this.pas = 1;
			this.$refs.showPas.type = 'password';
		},
		// 显示密码
		NewshowPas() {
			this.newPas = 2;
			this.$refs.showPas.type = 'text';
		},
		// 隐藏密码
		NewblankPas() {
			this.newPas = 1;
			this.$refs.showPas.type = 'password';
		},
		//实现 短信和账号的切换颜色
		showback() {
			this.type = this.login_types.fast;
			this.$refs.btn1.style = 'color:#fff';
			this.$refs.btn2.style = 'color:#575757';

      this.$nextTick(() => {
        this.$refs.input1.focus()
      })
		},
		showBck1() {
			this.type = this.login_types.account;
			this.$refs.btn1.style = 'color:#575757';
			this.$refs.btn2.style = 'color:#fff';

      this.$nextTick(() => {
        this.$refs.input2.focus();
      })
		},
		focus(event) {
			console.log(event);
		},
		/* 显示微信弹框 */
		showWechate() {
			this.LWhate = true;
			this.showLogin = false;
		},
		/* 去登陆 */
		/* toLogin(){
      this.LWhate = false;
      this.showLogin = true;
    } */

    //按钮监听键盘
    keyDown () {
      let that = this
      //监听键盘按钮
      document.onkeydown = function (event) {
        var e = event || window.event;
        var keyCode = e.keyCode || e.which;
        switch (keyCode) {
          case 13://enter
            that.submitForm( that.type )
            break;
          default:
            break;
        }
      }
    }
	}
};
</script>

<style lang="less" scoped>
.item-logo{
  width: 8em;
  height: 3em;
  margin-top: 20px;
}
.login {
  padding-top: 60px;
	.login-width {
		width: 380px;
		background: #ffffff;
		box-shadow: 0px 0px 10px 0px #cccccc;
		margin: 0 auto;
	}

	.login-center-width {
		width: 300px;
		margin: 0 auto;

		.login-center-width-img {
			text-align: center;
			margin-bottom: 40px;

			img {
				width: 162px;
				height: 32px;
				object-fit: fill;
			}
		}

		.login-center-width-dxzh {
			display: flex;
			justify-content: space-between;

			button {
				background-color: #efefef;
				margin-bottom: 20px;
			}

			/* 此处后期可以改变为动态颜色 为蓝色 */
		}

		.login-center-width-dxzh div > .active {
			background-color: #00a2e6;
			color: #fff;
		}

		.login-duanxi-input {
			.login-duanxi-input1 {
				width: 300px;
				margin-bottom: 20px;

				p {
					font-size: 10px;
					color: #e4007f;
					font-weight: 400;
					margin-left: 10px;
				}
			}

			.login-duanxi-input2 {
				width: 300px;
				margin-bottom: 20px;

				.login-duanxi-yanzheng {
					display: flex;
					justify-content: space-between;
				}

				p {
					font-size: 10px;
					color: #e4007f;
					font-weight: 400;
				}
			}
		}

		.login-zhanghao-input {
			.login-zhanghao-input1,
			.login-zhanghao-input2 {
				position: relative;
				width: 300px;
				margin-bottom: 20px;

				.capptalize {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 38px;
					top: 10px;
					z-index: 1;
					background-repeat: no-repeat;
					background-size: 16px 16px;
					cursor: pointer;
				}

				.visible-password {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 10px;
					top: 10px;
					z-index: 1;
					cursor: pointer;
				}

				.invisible-password {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 10px;
					top: 10px;
					z-index: 1;
					cursor: pointer;
				}

				p {
					margin-left: 10px;
					font-size: 10px;
					font-weight: 400;
					color: #e4007f;
				}
			}
		}

		.login-jizhu {
			display: flex;
			justify-content: space-between;
			margin: 20px 0 0 0;

			.login-left {
				width: 300px;
				display: flex;
				align-items: baseline;
				justify-content: space-between;
				cursor: pointer;

				input {
					display: block;
					width: 12px;
					height: 12px;
					margin-right: 10px;
				}

				span {
					position: relative;
					top: -1px;
					display: block;
					font-size: 12px;
					color: #575757;
				}

				.login-left-span {
					position: relative;
					left: -30px;
				}

				.span {
					display: block;
					font-size: 12px;
					color: #00a2e6;
					cursor: pointer;
				}
			}
		}
	}
}

.login-button {
	margin-top: -6px;
}

.checked() {
	cursor: pointer;
}

/deep/ .el-button {
	background-color: none;
	color: #fff;
	border: none;
}

.button-blue-400 {
	line-height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;

	/deep/ span {
		position: relative;
		top: 1px;
	}
}
</style>
