<template>
  <!-- 微信边框 -->
  <div class="wechate-box" v-loading="showLoading">
    <!-- 成功 -->
    <div class="wechate-box-errr" v-show="status == 9 || status==10">
      <div class="wechate-box-err-box"  id="wechate-box-img">
        <img id="wechate-box-img-img" src="@/assets/weixin.png" style="width:100%;height:100%"/>
        <div class="wechate-box-err-refresh" v-show="status == 10 ">
          <div class="refresh-box">
            <p style="display: block;padding-bottom: 10px;"><i class="iconfont icon-success" style="font-size: 26px;color: green"></i></p>
            <p>{{ okMsg }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 失败 -->
    <div class="wechate-box-errr" v-show="status == 1">
      <div class="wechate-box-err-box">
        <img src="@/assets/weixin.png" alt="" v-lazy />
        <div class="wechate-box-err-refresh">
          <div class="refresh-box">
            <p>遇到问题</p>
            <p>点击重试</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 二维码过期 -->
    <div class="wechate-box-errr" v-show="status == 2">
      <div class="wechate-box-err-box">
        <img src="@/assets/weixin.png" alt="" v-lazy />
        <div class="wechate-box-err-refresh">
          <div class="refresh-box">
            <p>二维码已过期</p>
            <p>点击刷新</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-20"></div>
    <p class="f-14 h-57">请使用 <span class="b cu">微信</span> 扫描二维码登录</p>
    <div class="mb-20"></div>
    <span class="f-12 h-57">已有账号？<b class="r cu" @click="toLogin">前往登录</b></span>
    <div class="mb-40"></div>

  </div>
</template>

<script>
import {requestWechatQrcode,requestWechatQuery,requestWechatBind} from "@/api"
import QRCode from 'qrcodejs2';

export default {
  name: "CheckWechat",
  data() {
    return {
      queryPaymentStatusTimer:null,
      queryPerMicSeconds: 2000,
      queryTimes: 0, //请求次数
      wechateChecked: false,
      showLoading: false,
      wechatData: {},
      random_code: "",
      status:0,//0loading  9生成成功  2过期 1失败
      okMsg:'扫码成功',
    }
  },
  created() {
    this.$nextTick(() => {
      this.check();
    });
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.queryPaymentStatusTimer != null) {
      clearInterval(this.queryPaymentStatusTimer);
    }
  },
  methods: {
    toLogin() {
      this.$bus.$emit("toLogins");
    },
    async check() {
      this.showLoading = true;
      let result = await requestWechatQrcode();
      if (result.statusCode == 200 ) {
        this.wechatData = result.data;
        this.generateQRCode();
      }else{
        this.status = 1;
        this.showLoading = false;
      }
    },
    generateQRCode() {
      let img = document.getElementById('wechate-box-img-img');
      img.src = this.wechatData.qrcode_url;
      let _this = this;
      setTimeout(function(){
        _this.queryTimes = 0;
        _this.startQueryPaymentTimer();
        _this.status = 9;
        _this.showLoading = false;
      },1000);
    },

    startQueryPaymentTimer() {
      this.queryPaymentStatusTimer = setInterval(this.queryPaymentStatus, this.queryPerMicSeconds);
    },
    stopQueryPaymentTimer() {
      if (this.queryPaymentStatusTimer != null) {
        clearInterval(this.queryPaymentStatusTimer);
      }
    },
    queryPaymentStatus() {
      this.queryTimes++;
      let querySeconds = (this.queryTimes * this.queryPerMicSeconds) / 1000; //请求的秒数

      //请求超时
      if (querySeconds > this.wechatData.expire_seconds) {
        this.status = 2;
        clearInterval(this.queryPaymentStatusTimer);
      }
      requestWechatQuery({ random_code:this.wechatData.random_code }).then((res)=>{
        let _this = this;
        if (res.statusCode == 200 && res.data.is_scan == 1 ) {
          let data = res.data.login_info;
          let user = res.data?.login_info.user
          this.status = 10;
          clearInterval(this.queryPaymentStatusTimer);

          // 标记且填充数据
          this.$store.dispatch('useUserStore/setLoginStatusInfo', data)

          var currentIdentity = localStorage.getItem('identity_' + user.id);
          if (currentIdentity == null) {
            localStorage.setItem('identity_' + user.id, 1);
          }
          // this.$root.type = data.type; //1正常用户 2匿名用户
          // this.$root.isLogin = true;
          // this.$root.user = data;
          // this.$root.current_token = data.token;

          //正常用户，返回上一层， 匿名用户跳转绑定
          if([1,3].includes(parseInt(user.type))){
            this.okMsg = '登录成功 正在为您跳转...';
            setTimeout(function (){
              _this.$router.go(-1);
            },2300);
          }else{
            this.okMsg = '请绑定一个手机号码';
            setTimeout(function (){
              _this.$router.push('/bind/mobile')
            },2300);
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.wechate-box {
  width: 230px;
  height: 346px;
  margin: 0 auto;
  text-align: center;

  .wechate-box-success{
    width:180px;
    margin:0 auto;
  }
  .wechate-box-img {
    img {
      width: 170px;
      height: 170px;
    }
  }

  p {
    display: inline;
  }

  /* loading状态 */

  .wechate-box-loading {
    animation: svgLoding 1s infinite linear;

    svg {
      margin-top: 45px;
      width: 80px;
      height: 80px;
      margin-bottom: 40px;
    }

  }

  /* 二维码过期 */

  .wechate-box-errr {
    width: 170px;
    margin: 0 auto;
    text-align: center;

    .wechate-box-err-box {
      width: 170px;
      height: 170px;
      position: relative;

      img {
        width: 170px;
        height: 170px;
      }
    }

    .wechate-box-err-refresh {
      position: absolute;
      width: 170px;
      height: 170px;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .refresh-box {
        width: 83px;
        height: 31px;
      }

      p {
        cursor: pointer;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .wechate-checked {
    height: 12px;
    display: flex;
    align-items: center;
  }
}

// @keyframes svgLoding{
// 	100%{
// 		transform:rotate(360deg);
// 		-ms-transform:rotate(360deg); /* Internet Explorer */
// 		-moz-transform:rotate(360deg); /* Firefox */
// 		-webkit-transform:rotate(360deg); /* Safari 和 Chrome */
// 		-o-transform:rotate(360deg); /* Opera */
// 		}
// }
</style>
